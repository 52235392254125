import { useState, useRef, RefObject, useMemo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { useRequestPasswordResetMutation } from '@magentoTypes'
import { useForm } from 'react-hook-form'
import { createMagentoClient } from '~/graphql/magentoClient'
import { DEFAULT_LOCALE, EMAIL_PATTERN } from '~/config/constants'
import { AccountMode } from '~/modules/AuthPanel/AuthPanel'
import { InputMain } from '~/elements/Input/InputMain'
import ReCAPTCHA from 'react-google-recaptcha'

import { toast } from 'react-toastify'
import { ButtonMain } from '~/elements/Button/ButtonMain'
import { useRouter } from 'next/router'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { log } from '~/lib/log'
type FormData = {
  email: string
}
type Props = {
  setAccountMode: (accountMode: AccountMode) => void
}
export const RequestPasswordReset = ({ setAccountMode }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState<boolean>(false)
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: true, queryClient, locale: finalLocale })
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false)
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [token, setToken] = useState<string | undefined>('')

  const options = useMemo(
    () => ({
      'X-ReCaptcha': token ?? '',
    }),
    [token],
  )

  const requestPasswordReset = useRequestPasswordResetMutation(
    magentoClient,
    {
      onSuccess: (data, variables) => {
        if (data.requestPasswordResetEmail) {
          setIsEmailSent(true)
          toast.success(
            t('An email has just been sent to {{email}} with the link to reset your password', {
              email: variables.email,
            }),
          )
        } else {
          toast.error(t('An error occurred'))
        }
      },
      onError: () => {
        toast.error(t('An error occurred'))
      },
      onSettled: () => {
        setLoading(false)
      },
    },
    options,
  )

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      email: '',
    },
  })

  const onSubmitHandler = useCallback(
    async (email: string) => {
      setLoading(true)

      try {
        recaptchaRef?.current?.reset()
        const newToken = await recaptchaRef?.current?.executeAsync()

        if (!newToken) {
          throw new Error('Failed to obtain reCAPTCHA token')
        }

        setToken(newToken)

        await new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            requestPasswordReset.mutate(
              { email },
              {
                onSuccess: (data) => {
                  if (data.requestPasswordResetEmail) {
                    resolve()
                  } else {
                    reject()
                  }
                },
                onError: () => {
                  reject()
                },
              },
            )
          }, 50)
        })
      } catch (error) {}
    },
    [requestPasswordReset],
  )

  const onSubmit = handleSubmit(() => onSubmitHandler(getValues('email')))

  return (
    <div>
      <form onSubmit={onSubmit}>
        {isEmailSent ? (
          <>
            <p className="mt-16">
              {t('An email has just been sent to {{email}} with the link to reset your password', {
                email: getValues('email'),
              })}
            </p>
          </>
        ) : (
          <>
            <InputMain
              type="text"
              register={register}
              name="email"
              errors={errors}
              placeholder="Email"
              autoComplete="email"
              rules={{
                required: { value: true, message: t('Email is required') },
                pattern: { value: EMAIL_PATTERN, message: t('Please enter a valid email') },
              }}
            />
            <ButtonMain disabled={loading} fullWidth className="mt-4" type="submit" variant="Main">
              {loading ? t('Loading...') : t('Reset password')}
            </ButtonMain>
          </>
        )}
        <ButtonMain
          className="mt-8"
          variant="Text"
          onClick={() => setAccountMode(AccountMode.Login)}
        >
          {t('Back to login')}
        </ButtonMain>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef as RefObject<ReCAPTCHA>}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY!}
        onError={(e) => {
          toast.error(t('We are having trouble identifying you as a human. Please try again.'))
        }}
        onExpired={() => {
          log('The token expired')
        }}
      />
    </div>
  )
}
