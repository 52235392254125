import { useState } from 'react'
import { signIn } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { InputMain } from '~/elements/Input/InputMain'
import { Icon } from '~/elements/Icons/Icon'
import tailwindConfig from '~/theme'
import { useToggle } from '~/hooks/useToggle'
import { DEFAULT_LOCALE, EMAIL_PATTERN } from '~/config/constants'
import { Button } from '~/elements/Button/Button'
import { useCartId } from '~/hooks/useCartId'

import { useTransferGuestToUserWishlist } from '~/lib/transferGuestToUserWishlist'
import { getStoreCode } from '~/lib/getStoreCode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { ButtonMain } from '~/elements/Button/ButtonMain'

export enum AccountMode {
  Login = 'LOGIN',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  Register = 'REGISTER',
  ResetPassword = 'RESET_PASSWORD',
}

type FormData = {
  email: string
  password: string
}

type Props = {
  setAccountMode: (accountMode: AccountMode) => void
  accountMode: AccountMode
}

export const LoginForm = () => {
  return <div></div>
}

export const Login = ({ setAccountMode, accountMode }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [_cartId, _cartLoading, resetCartId] = useCartId()
  const [loading, setLoading] = useState<boolean>(false)
  const [showPassword, togglePassword] = useToggle(false)
  const queryClient = useQueryClient()
  const transferGuestToUserWishlist = useTransferGuestToUserWishlist()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    criteriaMode: 'all',
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = handleSubmit(async ({ email, password }) => {
    // try to login the user
    setLoading(true)
    const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE

    try {
      const response = await signIn<'credentials'>('credentials', {
        username: email,
        password,
        callbackUrl: '/account',
        redirect: false,
        store: getStoreCode({ locale: finalLocale }),
      })
      if (response?.error) {
        setLoading(false)
        toast.error(t(response?.error?.split(':')[0]))
      }
      if (response?.ok && !response?.error) {
        await queryClient.invalidateQueries(['session'])
        setLoading(false)
        reset()
        toast.success(t('Login successful'))
        await queryClient.invalidateQueries()
        await transferGuestToUserWishlist()
        await resetCartId(true)
        await router.push('/account')
      }
    } catch (err) {
      setLoading(false)
      reset()
      toast.error(t('Server error'))
      console.error(err)
    }
  })
  const handleClickRequest = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAccountMode(AccountMode.RequestResetPassword)
    e.preventDefault()
  }

  return (
    <div>
      <aside>
        {accountMode === AccountMode.Login && (
          <>
            {/* <ExternalAuthButtons></ExternalAuthButtons> */}
            <form onSubmit={onSubmit}>
              <div>
                <InputMain
                  type="text"
                  placeholder="E-Mail"
                  name="email"
                  autoComplete="email"
                  rules={{
                    required: {
                      value: true,
                      message: t('{{val}} is required', { val: 'E-Mail' }),
                    },
                    pattern: { value: EMAIL_PATTERN, message: t('Please enter a valid email') },
                  }}
                  register={register}
                  errors={errors}
                />
              </div>
              <div>
                <InputMain
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('Password')}
                  rules={{
                    required: {
                      value: true,
                      message: t('{{val}} is required', { val: t('Password') }),
                    },
                  }}
                  autoComplete="current-password"
                  register={register}
                  errors={errors}
                >
                  {!!watch('password') && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        togglePassword()
                      }}
                      className={clsx('absolute right-4 top-1/2 z-20 -translate-y-1/2')}
                      disabled={!watch('password')}
                    >
                      {showPassword ? (
                        <Icon name="hide-password" className="h-4.5 w-4.5 stroke-sg-black" />
                      ) : (
                        <Icon name="show-password" className="h-4.5 w-4.5 stroke-sg-dark-grey" />
                      )}
                    </button>
                  )}
                </InputMain>
              </div>

              <div className={clsx('mt-[33px] mb-12 flex justify-between md:mt-6 md:mb-8')}>
                <ButtonMain type="button" variant="TextSmall" onClick={handleClickRequest}>
                  {t('Forgot Password')}
                </ButtonMain>
              </div>
              <ButtonMain
                variant="Secondary"
                type="submit"
                disabled={loading}
                fullWidth
                title="submit-login"
              >
                {loading ? t('Loading...') : t('Login')}
              </ButtonMain>
            </form>
          </>
        )}
      </aside>
    </div>
  )
}
