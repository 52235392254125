import { useQueryClient } from '@tanstack/react-query'
import { useResetPasswordMutation } from '__generated__/magentoTypes'
import clsx from 'clsx'
import { ButtonMain } from '~/elements/Button/ButtonMain'
import { InputMain } from 'elements/Input/InputMain'
import { createMagentoClient } from 'graphql/magentoClient'
import { useToggle } from 'hooks/useToggle'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { RefObject, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { DEFAULT_LOCALE, EMAIL_PATTERN } from '~/config/constants'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { log } from '~/lib/log'
import { Icon } from '../../../elements/Icons/Icon'
import tailwindConfig from '../../../theme'

type FormData = {
  password: string
  confirmPassword: string
  email: string
}

export const ResetPassword = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [token, setToken] = useState('')
  const recaptchaRef = useRef<ReCAPTCHA>()
  const [showPassword, togglePassword] = useToggle(false)
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: true, queryClient, locale: finalLocale })
  const resetPassword = useResetPasswordMutation(
    magentoClient,
    {
      onError: (error) => {
        toast.error(error?.message?.split(':')?.[0])
        setLoading(false)
        recaptchaRef?.current?.reset?.()
      },
      onSuccess: async (data) => {
        toast.success(t('Password has been reset'))
        setLoading(false)
        recaptchaRef?.current?.reset?.()
        await router.push('/login')
      },
    },
    {
      'X-ReCaptcha': recaptchaRef?.current?.getValue?.() ?? '',
    },
  )
  const { query } = router
  useEffect(() => {
    if (query.token) {
      setToken(query.token.toString())
    }
  }, [query.token])
  const password = useRef({})

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    criteriaMode: 'all',
    defaultValues: {
      password: '',
      confirmPassword: '',
      email: '',
    },
  })

  password.current = watch('password', '')
  const onSubmit = handleSubmit(async ({ password, confirmPassword, email }) => {
    setLoading(true)

    try {
      const tokenRec = await recaptchaRef?.current?.executeAsync()

      if (tokenRec) {
        //TODO: find a more elegant way to wait for the captcha token
        setTimeout(() => {
          if (token) {
            resetPassword.mutate({ email, password, resetPasswordToken: token })
          } else {
            toast.error(t('No password reset token specified'))
          }
        }, 50)
      }
    } catch (err) {
      reset()
      console.error(err)
      setLoading(false)
    }
  })
  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault()
    togglePassword()
  }

  const renderHideButton = () => {
    return (
      <button
        onClick={handleToggle}
        className={clsx('absolute right-4 top-1/2 z-20 -translate-y-1/2')}
        disabled={!watch('password')}
      >
        {showPassword ? (
          <Icon name="hide-password" className="h-4.5 w-4.5 stroke-sg-black" />
        ) : (
          <Icon name="show-password" className="h-4.5 w-4.5 stroke-sg-dark-grey" />
        )}
      </button>
    )
  }
  return (
    <article>
      <form onSubmit={onSubmit}>
        <ReCAPTCHA
          ref={recaptchaRef as RefObject<ReCAPTCHA>}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string}
          onError={(e) => {
            toast.error(t('We are having trouble identifying you as a human. Please try again.'))
            console.error(e)
          }}
          onExpired={() => {
            log('The token expired')
            recaptchaRef?.current?.reset()
          }}
        />
        <div className={clsx('pt-[7px]')}>
          <InputMain
            type="text"
            placeholder="E-Mail"
            name="email"
            autoComplete="email"
            rules={{
              required: {
                value: true,
                message: t('{{val}} is required', { val: 'E-Mail' }),
              },
              pattern: { value: EMAIL_PATTERN, message: t('Please enter a valid email') },
            }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={clsx('pt-[25px]')}>
          <InputMain
            register={register}
            name="password"
            rules={{
              required: {
                value: true,
                message: t('{{val}} is required', { val: t('Password') }),
              },
              minLength: { value: 8, message: t('A minimum of 8 characters required') },
            }}
            type={showPassword ? 'text' : 'password'}
            placeholder={t('Password')}
            autoComplete="current-password"
            errors={errors}
          >
            {renderHideButton()}
          </InputMain>
        </div>
        <div className={clsx('pt-[25px]')}>
          <InputMain
            name="confirmPassword"
            register={register}
            errors={errors}
            type={showPassword ? 'text' : 'password'}
            placeholder={t('Confirm Password')}
            autoComplete="current-password"
            rules={{
              validate: (value) => value === password.current || 'The passwords do not match',
              required: {
                value: true,
                message: t('{{val}} is required', { val: t('Password') }),
              },
            }}
          >
            {renderHideButton()}
          </InputMain>
        </div>
        <ButtonMain disabled={loading} fullWidth variant="Main" type="submit" className="mt-10">
          {loading ? t('Loading...') : t('Change Password')}
        </ButtonMain>
      </form>
    </article>
  )
}
