import {
  AddToWishlistDocument,
  AddToWishlistMutation,
  GetWishlistIdQuery,
  useAddToWishlistMutation,
  useFetchUserWishlistQuery,
  useGetWishlistIdQuery,
} from '@magentoTypes'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import invariant from 'tiny-invariant'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createMagentoClient } from '~/graphql/magentoClient'
import useLocalStorage from '~/hooks/useLocalStorage'
import { fetchSession, useSession } from '~/hooks/useSession'
import { getStoreCode } from '~/lib/getStoreCode'
import { createLanguageRegionLocale } from './createLanguageRegionLocale'

export const useTransferGuestToUserWishlist = () => {
  const [session, loading] = useSession()
  const queryClient = useQueryClient()
  const router = useRouter()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ queryClient, locale: finalLocale })
  const mutationClient = createMagentoClient({ usePost: true, queryClient, locale: finalLocale })
  const storeCode = getStoreCode({ locale: finalLocale })
  const { data } = useGetWishlistIdQuery(magentoClient, undefined, {
    enabled: !!session,
  })
  const addToWishlistMutation = useAddToWishlistMutation(mutationClient)
  const [guestWishlistSkus] = useLocalStorage<string[]>('guestWishlistSku', [])

  const transferWishlist = async () => {
    const freshSession = await fetchSession()
    queryClient.setQueryData(['session'], freshSession)
    if (guestWishlistSkus.length > 0 && freshSession) {
      invariant(process.env.NEXT_PUBLIC_STORE_CODE, 'No NEXT_PUBLIC_STORE_CODE')

      const headers = {
        Authorization: `Bearer ${freshSession.user.token}`,
        Store: storeCode ?? 'benuta_de',
      }
      const wishlistIdData = await magentoClient.request<GetWishlistIdQuery>(
        useGetWishlistIdQuery.document,
        undefined,
        headers,
      )
      try {
        await mutationClient.request<AddToWishlistMutation>(
          AddToWishlistDocument,
          {
            wishlistId: wishlistIdData.customer?.wishlists[0]?.id ?? '0',
            wishlistItems: guestWishlistSkus.map((item) => {
              return { sku: item, quantity: 1 }
            }),
          },
          headers,
        )
        queryClient.invalidateQueries(useFetchUserWishlistQuery.getKey({}))
      } catch (error) {
        toast.error(JSON.parse(JSON.stringify(error)).response.errors[0].message)
      }
    }
  }

  return transferWishlist
}
