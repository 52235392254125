import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { ResetPassword } from 'modules/AuthPanel/components/ResetPassword'
import { Login } from './components/Login'
import { Register } from 'modules/AuthPanel/components/Register'
import { RequestPasswordReset } from 'modules/AuthPanel/components/RequestPasswordReset'
import { useRouter } from 'next/router'
import { useSession } from '~/hooks/useSession'
import { ButtonMain } from '~/elements/Button/ButtonMain'

export enum AccountMode {
  Login = 'LOGIN',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  Register = 'REGISTER',
  ResetPassword = 'RESET_PASSWORD',
}

type Props = {
  isUserResettingPassword?: boolean
}
const headerStyle = 'pb-6 text-center text-h3-mobile lg:pb-10 lg:text-h3-desktop'

export const AuthPanel = ({ isUserResettingPassword }: Props) => {
  const router = useRouter()
  const { authmode } = router.query
  const [session] = useSession()

  const [accountMode, setAccountMode] = useState<AccountMode>(
    isUserResettingPassword || authmode === 'resetpw'
      ? AccountMode.ResetPassword
      : AccountMode.Login,
  )

  const { t } = useTranslation()

  useEffect(() => {
    const redirectToAccount = async () => {
      await router.push('/account')
    }
    if (session) {
      redirectToAccount()
    }
  }, [session, router])

  useEffect(() => {
    //reset to login panel if user clicks on account button
    if (router?.query?.authmode === 'login') {
      setAccountMode(AccountMode.Login)
    }
  }, [router.query])

  const getAccountModeFirstWindow = (accountMode: AccountMode) => {
    switch (accountMode) {
      case AccountMode.Login:
        return (
          <>
            <h2 className={clsx(headerStyle)}>{t('Login')}</h2>
            <Login setAccountMode={setAccountMode} accountMode={accountMode} />
          </>
        )
      case AccountMode.Register:
        return (
          <>
            <h2 className={clsx(headerStyle)}>{t('Login')}</h2>
            <ButtonMain
              variant="Secondary"
              fullWidth
              onClick={() => setAccountMode(AccountMode.Login)}
            >
              {t('I already have an account')}
            </ButtonMain>
          </>
        )
      case AccountMode.RequestResetPassword:
        return (
          <>
            <h2 className={clsx(headerStyle)}>{t('Forgot password')}</h2>
            <RequestPasswordReset setAccountMode={setAccountMode} />
          </>
        )
      case AccountMode.ResetPassword:
        return (
          <>
            <h2 className={clsx(headerStyle)}>{t('Change password')}</h2>
            <ResetPassword />
          </>
        )
      default:
        return <></>
    }
  }

  const getAccountModeSecondWindow = (accountMode: AccountMode) => {
    switch (accountMode) {
      case AccountMode.Login:
        return (
          <>
            <h2 className={clsx(headerStyle)}>{t('New at benuta?')}</h2>
            <ButtonMain fullWidth onClick={() => setAccountMode(AccountMode.Register)}>
              {t('Register new')}
            </ButtonMain>
          </>
        )
      case AccountMode.Register:
        return (
          <>
            <h2 className={clsx(headerStyle)}>{t('Register new')}</h2>
            <Register />
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <article>
      <h1
        className={clsx(
          'border-0 border-b border-solid border-grey-border py-8 px-4 font-right-grotesk text-h1-desktop lg:pt-12 lg:pl-20 lg:pb-16 xl:pl-24',
        )}
      >
        {t('Welcome to benuta!')}
      </h1>
      <div
        className={clsx(
          'flex flex-col border-0 border-b border-solid border-grey-border md:flex-row lg:border-b-0',
        )}
      >
        <div
          className={clsx(
            'flex w-full justify-center  border-solid border-grey-border py-8 px-4 md:w-1/2 md:border-0 md:border-r md:py-14 lg:py-20 lgx:px-[115px] xl:py-32',
            accountMode !== AccountMode.RequestResetPassword &&
              accountMode !== AccountMode.ResetPassword &&
              'border-b',
          )}
        >
          <div
            className={clsx(
              'min-h-[320px] w-full md:min-w-[320px] lg:min-w-[380px] lg:max-w-[400px] lgx:min-w-[400px] xl:min-w-[450px] 2xl:min-w-[500px]',
            )}
          >
            {getAccountModeFirstWindow(accountMode)}
          </div>
        </div>
        <div
          className={clsx(
            'flex justify-center py-8 px-4 md:w-1/2 md:py-14 lg:py-20 lgx:px-[115px] xl:py-32',
          )}
        >
          <div
            className={clsx(
              'w-full md:min-w-[320px] md:max-w-[350px] lg:min-w-[380px] lg:max-w-[400px] lgx:min-w-[400px] xl:min-w-[450px] 2xl:min-w-[500px]',
            )}
          >
            {getAccountModeSecondWindow(accountMode)}
          </div>
        </div>
      </div>
    </article>
  )
}
